import React from 'react';
import { Container, Grid, Typography, Box, Link } from '@mui/material';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <Box component="footer" className="footer">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">Danso Health</Typography>
            <Typography variant="body2" className="footer-description">
              Premium concierge staffing services for medical doctors, offering tailored staffing solutions, compliance support, housing, and professional development opportunities.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">Quick Links</Typography>
            <ul className="footer-links">
              <li><Link href="#about" color="inherit">About Us</Link></li>
              <li><Link href="#services" color="inherit">Services</Link></li>
              <li><Link href="#job-opportunities" color="inherit">Job Opportunities</Link></li>
              <li><Link href="#contact" color="inherit">Contact</Link></li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="footer-title">Contact Us</Typography>
            <Typography variant="body2" className="footer-description">
              Email: info@dansohealth.com<br />
              Phone: (347) 755-0651<br />
            </Typography>
          </Grid>
        </Grid>
        <Box className="footer-bottom">
          <Typography variant="body2" className="footer-copy">&copy; {new Date().getFullYear()} Danso Health. All rights reserved.</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
