import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/AboutUsModal.css';

const AboutUsModal = ({ open, onClose, title, content }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="dialog-title">
        {title}
        <IconButton className="dialog-close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className="dialog-content">
          {content}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AboutUsModal;
