import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import HowItWorksSection from './components/HowItWorksSection';
import AboutSection from './components/AboutSection';
import Footer from './components/Footer'; // Import the Footer component

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection id="hero" />
      <HowItWorksSection id="how-it-works" />
      <AboutSection id="about" />
      <Footer /> {/* Add the Footer component */}
    </div>
  );
}

export default App;
