import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';
import '../styles/AboutUsCard.css';

const AboutUsCard = ({ title, description, onClick }) => {
  return (
    <Card className="about-us-card" onClick={onClick}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" component="div" className="card-title">
            {title}
          </Typography>
          <Typography variant="body2" className="card-description">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AboutUsCard;
