import React, { useState } from 'react';
import '../styles/Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); // Close the menu after navigation
    }
  };

  return (
    <header className="header">
      <div className="top-nav">
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/assets/DansoLogonoN.png`} alt="Danso Health Logo" />
          <span>Danso Health</span>
        </div>
        <nav>
          <ul className={`nav-links ${menuOpen ? 'active' : ''}`} aria-label="Primary Navigation">
            <li><button onClick={() => scrollToSection('#about')}>About</button></li>
            <li><button onClick={() => scrollToSection('#how-it-works')}>services</button></li>
            <li><button onClick={() => scrollToSection('#job-opportunities')}>Job Opportunities</button></li>
            <li><button onClick={() => scrollToSection('#contact')}>Contact</button></li>
          </ul>
          <div className="nav-toggle" onClick={toggleMenu} aria-label="Toggle navigation menu" aria-expanded={menuOpen}>
            &#9776;
          </div>
        </nav>
        <div className="header-buttons">
          <button onClick={() => scrollToSection('#search-jobs')} className="button-outline">Search Jobs</button>
          <button onClick={() => scrollToSection('#sign-up')} className="button-filled">Sign Up</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
