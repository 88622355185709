import React, { useEffect, useRef } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import '../styles/HeroSection.css';

const doctorImage = process.env.PUBLIC_URL + '/assets/doc.png';

const HeroSectionComponent = ({ id }) => {
  const heroSectionRef = useRef(null);

  useEffect(() => {
    // Copy the ref value to a local variable
    const node = heroSectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          } else {
            entry.target.classList.remove('animate');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      // Use the local variable in the cleanup function
      if (node) {
        observer.unobserve(node);
      }
    };
  }, []);

  return (
    <Box className="hero-section" id={id} ref={heroSectionRef}>
      <Container className="hero-content">
        <Typography variant="h2" component="h1" className="hero-title">
          Elevate Your Medical Career with Unmatched Excellence
        </Typography>
        <Typography variant="h5" component="p" className="hero-subtitle">
          Discover the Premier Concierge Staffing Service for Medical Doctors
        </Typography>
        <Button variant="contained" color="primary" className="hero-button">
          Join Us Today
        </Button>
      </Container>
      <img src={doctorImage} alt="Doctor" className="hero-image" />
    </Box>
  );
};

export default HeroSectionComponent;
