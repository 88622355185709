import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import '../styles/HowItWorksSection.css';

// Reference the images directly from the public directory
const staffingImage = process.env.PUBLIC_URL + '/assets/members2.png';
const housingImage = process.env.PUBLIC_URL + '/assets/DansoEstate.png';
const conciergeImage = process.env.PUBLIC_URL + '/assets/rolesforDoc.png';
const servicesImage = process.env.PUBLIC_URL + '/assets/conceirge1.png';

const HowItWorksSection = () => {
  return (
    <Box className="how-it-works-section">
      <Container>
        <Typography variant="h2" component="h2" className="section-title">
          How It Works
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box className="how-it-works-step">
              <Typography variant="h4" component="h3" className="step-title">
                Invite-Only Membership
              </Typography>
              <img src={staffingImage} alt="Exclusive Membership" className="how-it-works-image" />
              <Typography variant="body1" component="p" className="step-description">
                We curate an elite network of top-tier medical doctors. Membership is by invitation only, ensuring our community is comprised of the best in the field.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="how-it-works-step">
              <Typography variant="h4" component="h3" className="step-title">
                Personalized Role Sourcing
              </Typography>
              <img src={conciergeImage} alt="Role Sourcing" className="how-it-works-image" />
              <Typography variant="body1" component="p" className="step-description">
                We source roles that match your needs for flexibility, location, and contract duration. Whether short-term or long-term, we find the perfect opportunities for you.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="how-it-works-step">
              <Typography variant="h4" component="h3" className="step-title">
                Comprehensive Housing
              </Typography>
              <img src={housingImage} alt="Housing and Transportation" className="how-it-works-image" />
              <Typography variant="body1" component="p" className="step-description">
                Enjoy comfortable and luxurious housing along with reliable transportation. We handle all logistics to ensure your transition is seamless.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="how-it-works-step">
              <Typography variant="h4" component="h3" className="step-title">
                Premium Concierge Services
              </Typography>
              <img src={servicesImage} alt="Premium Services" className="how-it-works-image" />
              <Typography variant="body1" component="p" className="step-description">
                From finding a vetted nanny to booking an exclusive date night, our personal concierge services take care of every detail, making your life easier and more enjoyable.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
