import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import AboutUsCard from './AboutUsCard';
import AboutUsModal from './AboutUsModal';
import '../styles/AboutSection.css';

const AboutSection = ({ id }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const containerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = (title, content) => {
    setModalContent({ title, content });
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const cardsData = [
    {
      title: 'Our Mission',
      description: 'Empowering Healthcare, Elevating Lives',
      content: 'Our mission is to bridge the gap between exceptional medical talent and the healthcare facilities that need them. We are committed to fostering an elite network of medical professionals and institutions united by a shared commitment to quality, professionalism, and excellence.',
    },
    {
      title: 'Our Story',
      description: 'A Journey of Innovation and Dedication',
      content: 'Danso Health was born out of the realization that healthcare staffing needed a transformative approach. Inspired by the relentless pursuit of excellence that defined Steve Jobs\' philosophy, our founder envisioned a service that would not only meet but exceed the expectations of both doctors and healthcare facilities.',
    },
    {
      title: 'What Sets Us Apart',
      description: 'A New Standard in Healthcare Staffing',
      content: 'We offer exclusive networks, personalized service, comprehensive support, and a commitment to excellence, ensuring we meet the unique needs of each doctor and healthcare institution we serve.',
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsExpanded(true);
        } else {
          setIsExpanded(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentContainer = containerRef.current;
    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      className={`about-us-container ${isExpanded ? 'expanded' : ''}`}
      id={id}
    >
      <Typography variant="h4" className="about-us-title">
        Get to Know Danso Health
      </Typography>
      <Grid container spacing={4} className="about-us-grid">
        {cardsData.map((card, index) => (
          <Grid item xs={12} md={4} key={index}>
            <AboutUsCard
              title={card.title}
              description={card.description}
              onClick={() => handleCardClick(card.title, card.content)}
            />
          </Grid>
        ))}
      </Grid>
      <AboutUsModal
        open={modalOpen}
        onClose={handleClose}
        title={modalContent.title}
        content={modalContent.content}
      />
    </Container>
  );
};

export default AboutSection;
